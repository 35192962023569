import React, { useEffect, useState, useMemo } from 'react';


function Home2(props) {

  return (
   <><div class="grid-container">
    {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16].map(() => {
      return <>  
      <div class="grid-item">Imagem 1</div>
      <div class="grid-item">Imagem 2</div>
      <div class="grid-item">Imagem 3</div>
      <div class="grid-item">Imagem 4</div>
      </>

    }
    )}
 </div>
 
   </>
  );
}

export default Home2;