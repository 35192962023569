import React, { useEffect, useState, useMemo, useRef } from "react";
import "./home.css";
import PhotoService from "../../services/photoServices";
import VideoService from "../../services/videoServices";
import VideoGlobal from "../../components/VideoGlobal";
import { useParams } from "react-router-dom";
import appConfig from "../../appConfig.json";

function App() {
  const { eventName } = useParams();
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [marcaDAgua, setMarcaDAgua] = useState(null);
  const [tempoEfeito, setTempoEfeito] = useState(null);
  const [opacity, setOpacity] = useState(appConfig.API.OPACITY);
  const [showPhotos, setShowPhotos] = useState(true);
  const [intervalo, setIntervalo] = useState(appConfig.API.INTERVALO * 60 * 1000);
  const [url, setUrl] = useState(null);
  const [effect, setEffect] = useState("zoom-in");
  const [fotoPremiacao, setFotoPremiacao] = useState(null);
  const intervalRef = useRef(null);
  const intervalUpdateRef = useRef(null);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [resetarPainelVideo, setResetarPainelVideo] = useState(false);
  const [aspectRatio, setAspectRatio] = useState({ width: 16, height: 9 });
  const [ordenado, setOrdenado] = useState(false);

  const [photoPositions, setPhotoPositions] = useState([]);

  useEffect(()=>{
    const getVideos = async (eventName) => {
      try {
        const resp = await VideoService.GetVideos(eventName, "");
        if (resp.Success) {
          const videoList = resp.LstClasse.map((video) => video.NomeVideo);
          setVideos(videoList);
        }
      } catch (ex) {
        console.log(ex);
      }
    };
    getVideos(eventName);
  },[]);

  useEffect(() => {
    if (photos.length > 0 && photoPositions.length === 0) {

      let configuracaoGrid = calcularGrid(aspectRatio.width, aspectRatio.height, photos.length, window.screen.width, window.screen.height)

      const numColumns = configuracaoGrid.colunas;
      const numRows = configuracaoGrid.linhas;

      const availablePositions = [];
      for (let row = 1; row <= numRows; row++) {
        for (let col = 1; col <= numColumns; col++) {
          availablePositions.push({ row, col });
        }
      }

      if (!ordenado) {
        const getRandomPosition = () => {
          const randomIndex = Math.floor(Math.random() * availablePositions.length);
          return availablePositions.splice(randomIndex, 1)[0];
        };

        const positions = photos.map(() => getRandomPosition());
        setPhotoPositions(positions);
      } else {
        function shuffleArrays(array1, array2) {
          // Verifique se os arrays têm o mesmo comprimento
          if (array1.length !== array2.length) {
            throw new Error("Os arrays devem ter o mesmo comprimento");
          }
        
          // Algoritmo de Fisher-Yates para embaralhar os arrays
          for (let i = array1.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            
            // Embaralhe array1
            [array1[i], array1[j]] = [array1[j], array1[i]];
        
            // Embaralhe array2 de forma sincronizada
            [array2[i], array2[j]] = [array2[j], array2[i]];
          }
        }
        let photoList = Object.assign([],  photos);
        let availablePositionsList = Object.assign([],  availablePositions);
        // shuffleArrays(photoList, availablePositionsList);

        setPhotoPositions(availablePositionsList);
        setPhotos(photoList);
        console.log(photoList, availablePositionsList)
      }
    }
  }, [photos, photoPositions]);



  useEffect(() => {
    setUrl(
      process.env.NODE_ENV !== "development"
        ? appConfig.API.PROD_ENDPOINT_PHOTO
        : appConfig.API.DEV_ENDPOINT_PHOTO
    );

    const getPhotos = async (eventName) => {
      try {
        if (showPhotos) {
          let resp2 = await PhotoService.GetPhotos(eventName, "");

          if (resp2.Success) {
            if (resp2.LstClasse && resp2.LstClasse.length > 0) {
              const configJson = JSON.parse(resp2.LstClasse[0].EventoConfig);
              let photoList = resp2.LstClasse.slice(0, configJson.QuantidadeFotos);

              if (configJson.ResetarPainelVideo) {
                setResetarPainelVideo(true);
              }

              setMarcaDAgua(
                configJson.Fundo
                  ? configJson.Fundo
                  : appConfig.API.FUNDO
              );

              setIntervalo(
                configJson.Intervalo
                  ? configJson.Intervalo * 60 * 1000
                  : appConfig.API.INTERVALO * 60 * 1000
              );

              const tempoEfeito = configJson.TempoEfeito * 1000 || appConfig.API.TEMPOEFEITO * 1000;
              setTempoEfeito(tempoEfeito);

              if (configJson.Efeito) {
                setEffect(configJson.Efeito);
              }

              if (configJson.FotoPremiacao) {
                setFotoPremiacao(configJson.FotoPremiacao);
              }
              if (configJson.AspectRatio) {
                setAspectRatio(configJson.AspectRatio);
              }
              if (configJson.Ordenado) {
                setOrdenado(configJson.Ordenado);
              }
              if(configJson.Opacidade){
                setOpacity(configJson.Opacidade);
              }

              let totalSpaces = configJson.QuantidadeFotos || 244;
              let data = [{ preenchido: false }];
              if(photoList[0].NomeFoto === ''){
                return;
              }
              while (photoList.length < totalSpaces) {
                photoList = [...photoList, ...data];
              }

              setPhotos([...photoList]);

            }
          }
        }
      } catch (ex) {
        console.log(ex);
      }
    };


    

    getPhotos(eventName);
   

    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    if (intervalUpdateRef.current) {
      clearInterval(intervalUpdateRef.current);
    }

    if (showPhotos) {
      intervalUpdateRef.current = setInterval(() => {
        getPhotos(eventName);
      }, 10000);
    }

    return () => {
      clearInterval(intervalUpdateRef.current);
    };
  }, [eventName, intervalo, showPhotos]);

  useEffect(() => {
    if (showPhotos) {
      clearInterval(intervalRef.current);
      intervalRef.current = setInterval(() => {
        if (videos.length > 0) {
          setShowPhotos(false);
        }
      }, intervalo);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [videos, showPhotos, intervalo]);

  useEffect(() => {
    if (photos.length > 0 && showPhotos) {
      const interval = setInterval(() => {
        setCurrentPhotoIndex((prevIndex) => {

          if (prevIndex >= photos.length - 1) {
            clearInterval(interval);
            return prevIndex;
          }
          let nextIndex = prevIndex + 1;

          return nextIndex < photos.filter((v) => v.preenchido !== false).length ? nextIndex : prevIndex;

        });
      }, tempoEfeito);

      return () => clearInterval(interval);
    }
  }, [photos, showPhotos]);


  const handleVideosEnd = () => {
    setShowPhotos(true);

    if (resetarPainelVideo) {
      setCurrentPhotoIndex(-1);
    }

    intervalRef.current = setInterval(() => {
      setShowPhotos(false);
    }, intervalo);

  };

  const getGridStyle = useMemo(() => {
    return {
      display: 'grid',
      gridAutoFlow: 'dense',
      gridTemplateColumns: 'repeat(auto-fit, minmax(40px, 1fr))',
      gridTemplateRows: 'auto',
      width: '100vw',
      height: '100vh',
      overflow: 'hidden',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'white'
    };
  }, [photos.length]);

  function calcularGrid(aspectRatioWidth, aspectRatioHeight, totalFotos, larguraTela = 1920, alturaTela = 1080) {
    // Calcula a área total da tela em pixels
    const areaTela = larguraTela * alturaTela;

    // Calcula a área ideal de cada foto (área da tela dividida pelo total de fotos)
    const areaPorFoto = areaTela / totalFotos;

    // Calcula a altura e a largura ideais da foto baseados no aspect ratio e na área
    const alturaFotoIdeal = Math.sqrt(areaPorFoto * (aspectRatioHeight / aspectRatioWidth));
    const larguraFotoIdeal = (alturaFotoIdeal * aspectRatioWidth) / aspectRatioHeight;

    // Calcula o número de colunas e linhas que cabem na tela com essas dimensões
    const numColunas = Math.floor(larguraTela / larguraFotoIdeal);
    const numLinhas = Math.ceil(totalFotos / numColunas);

    // Retorna os valores calculados
    return {
      colunas: numColunas,
      linhas: numLinhas,
      larguraFoto: larguraFotoIdeal.toFixed(2),
      alturaFoto: alturaFotoIdeal.toFixed(2),
      totalFotosCalculado: numColunas * numLinhas
    };
  }

  const getListPhotos = useMemo(() => {
    return photos.map((photo, index) => {
      const effectClass = index === currentPhotoIndex ? `photo-item ${effect}` :
        index < currentPhotoIndex ? `photo-item` : 'photo-item hidden-photo';

      let configuracaoGrid = calcularGrid(aspectRatio.width, aspectRatio.height, photos.length, window.screen.width, window.screen.height)

      const { row, col } = photoPositions[index] || {};
      const style = {
        '--tempo-efeito': `${tempoEfeito}ms`,
        gridRowStart: row,
        gridColumnStart: col,
        width: (Number(configuracaoGrid.larguraFoto) + 1) + 'px',
        height: configuracaoGrid.alturaFoto + 'px'
      };
      if (photo?.preenchido === false) {
        return (
          <div key={index} className="photo-item" alt={index} style={{ ...style, zIndex: 99 }}>
            <div className={`fundoBranco`}></div>
          </div>
        );
      } else {
        return (
          <div key={index} alt={effectClass} className={effectClass} style={style}>
            <img src={`${url}/${eventName}/${photo.NomeFoto}`} alt={`Foto ${index}`} />
            {photo.Premiada && (
              <img src={`${url}/${eventName}/${fotoPremiacao}`} className="img-premio"  />
            )}
          </div>
        );
      }
    });
  }, [photos, photoPositions, url, eventName, effect, currentPhotoIndex, fotoPremiacao, tempoEfeito]);


  const getStyle = useMemo(() => {
    return photos.length > 0
      ? { opacity: opacity, transition: "opacity 2s ease" }
      : { opacity: opacity };
  }, [photos, opacity]);



  return (
    <>
      {showPhotos && photos?.length > 0 && (
        <div className={`containerGlobal`}>
          <div className="photo-grid-1" style={getGridStyle}>
            {getListPhotos}
            <div className="logoGlobal" style={getStyle}>
              <img src={`${url}/${eventName}/${marcaDAgua}`} alt="Marca D'água" />
            </div>
          </div>
        </div>
      )}

      {showPhotos && marcaDAgua && photos?.length === 0 && (
         <div className={`containerGlobal`}>
         <div className="photo-grid-1" style={getGridStyle}>
           <div className="logoGlobal" style={getStyle}>
             <img src={`${url}/${eventName}/${marcaDAgua}`} alt="Marca D'água" />
           </div>
         </div>
       </div>
      )}

      {!showPhotos && videos.length > 0 && (
        <VideoGlobal
          style={true}
          eventName={eventName}
          urls={videos}
          onVideosEnd={handleVideosEnd}
        />
      )}
    </>
  );
}

export default App;
