import React from "react";
import ReactPlayer from "react-player";

function Homenagem() {
  return (
    <ReactPlayer
      url={"videos/homenagem.mp4"}
      width="100vw"
      height="100vh"
      controls={true}
      style={{
        overflow: "hidden",
        backgroundColor: "#000",
      }}
    />
  );
}

export default Homenagem;
