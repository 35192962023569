import axios from 'axios';


const PhotoService = {
	ObterPhotos() {
		return axios
			.get(
				`https://api.unsplash.com/photos/random?count=10&client_id=yEpNagMRryPIUh7kRJv-OdTnoaBv4s1k_6C6FK6SeEE&per_page=30&orientation=portrait`
			)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((error) => {
				return Promise.reject(error);
			});
	},
	Upload(eventName,formData) {
		return axios
			.post(`/Photo/Upload/${eventName}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((error) => {
				return Promise.reject(error);
			});
	},
	GetPhotos(eventName,tag) {
		return axios
			.post(`/Photo/GetPhotos/${eventName}`)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((error) => {
				return Promise.reject(error);
			});
	},
	DeleteAll(eventName) {
		return axios
			.post(`/Photo/DeleteAll/${eventName}`)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((error) => {
				return Promise.reject(error);
			});
	},
};

export default PhotoService;