import React, { useState, useRef, useEffect } from "react";
import VideoGlobal from "../../components/VideoGlobal";

function Monitor1() {
  return (
    <VideoGlobal
      path="videos/monitor1/"
      playbackRate={true}
      urls={[
        "R_01_fotos antigas_oswaldo_e_rodrigo.mp4",
        "R_02_kickoff_keyrus_2011.mp4",
        "R_03_dayoff_2011_pt_1.mp4",
        "R_04_dayoff_2011_pt_2.mp4",
        "R_05_15_anos_global_2014.mp4",
        "R_06_confra_2013.mp4",
        "R_07_confra_2014_pt_1.mp4",
        "R_08_confra_2014_pt_2.mp4",
        "R_09_aniversario_oswaldo_2015.mp4",
        "R_10_confra_2018_pt_1_.mp4",
        "R_11_confra_2018_pt_2.mp4",
        "R_12_confra_2018_pt_3.mp4",
        "R_13_confra_2018_pt_4.mp4",
        "R_14_confra_2018_pt_5.mp4",
        "R_15_confra_2019_pt_1.mp4",
        "R_16_confra_2019_pt_2.mp4",
        "R_17_confra_2019_pt_3.mp4",
        "R_18_confra_2019_pt_4.mp4",
        "R_19_confra_2019_pt_5.mp4",
        "R_20_confra_2022.mp4",
        "R_21_hallowen.mp4",
        "R_22_outubro_rosa_1.mp4",
        "R_23_novembro_azul.mp4",
        "R_25_dia_a_dia_.mp4",
        "R_26_times_externos_pt_1.mp4",
        "R_27_times_externos_pt_2.mp4",
        "R_28_times_externos_pt_3.mp4",
        "R_29_pe_na_areia_pt_1.mp4",
        "R_30_pe_na_areia_pt_2_.mp4",
        "R_31_pe_na_areia_pt_3.mp4",
        "R_32_copa_do_mundo_.mp4",
        "R_33_amigo_secreto_2023.mp4",
        "R_34_confra_2023_pt_1.mp4",
        "R_35_confra_2023_pt_2.mp4",
        "R_36_confra_2023_pt_3.mp4",
        "R_37_confra_2023_pt_4.mp4",
        "R_38_projetos.mp4",
        "R_39_meet_pt_1.mp4",
        "R_40_meet_pt_2.mp4",
        "R_41_INTRO_BusiNess_Solutions_purpura__.mp4",
        "R_42_INTRO_Global_intro_pixels Purpura_.mp4",
        "R_43_INTRO_global_glitch_purpura_.mp4",
        "R_44_INTRO_Globa_solo_pixels_Purpura_.mp4",
        "R_45_INTRO_global_pixels.mp4",
        "R_46_INTRO.mp4",
        "R_47_INTRO_planets.mp4",
        "R_48.mp4",
        "R_48_novo.mp4",
        "R_49.mp4",
        "R_49_novo.mp4",
        "R_50_novo.mp4",
        "R_51_novo.mp4",
        "R_52_novo.mp4",
        "R_53_novo.mp4",
        "R_54_novo.mp4",
        "R_55_novo.mp4",
      ]}
    />
  );
}

export default Monitor1;
