import React, { useState } from "react";
import PhotoService from "../../services/photoServices";
import VideoService from "../../services/videoServices";
import md5 from "md5";
import { useParams } from "react-router-dom";

const UploadMedia = () => {
  const [file, setFile] = useState(null);
  const [fileVideo, setFileVideo] = useState(null);
  const [previewURL, setPreviewURL] = useState("");
  const [error, setError] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [password, setPassword] = useState("");
  const { eventName } = useParams();

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png"];

    if (selectedFile && allowedTypes.includes(selectedFile.type)) {
      setFile(selectedFile);
      setError("");
      // Pré-visualização da imagem
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewURL(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setFile(null);
      setError("Selecione um arquivo de imagem válido (JPEG, PNG)");
      setPreviewURL("");
    }
  };

  const handleFileChangeVideo = (event) => {
    const selectedFile = event.target.files[0];
    const allowedTypes = ["video/mp4", "video/webm"];

    if (selectedFile && allowedTypes.includes(selectedFile.type)) {
      setFileVideo(selectedFile);
      setError("");
    } else {
      setFileVideo(null);
      setError("Selecione um arquivo de vídeo válido (MP4, WEBM)");
    }
  };

  const handleSubmit = async () => {
    if (md5(password) === "5c0528724ec1226519bd4a98b5c0e4b1") {
      if (!file) {
        alert("Nenhum arquivo selecionado");
        return;
      }

      const formData = new FormData();
      formData.append("file", file);

      try {
        await PhotoService.Upload(eventName, formData);
        alert("Imagem salva com sucesso");
        // Limpar estado após envio bem-sucedido
        setFile(null);
        setPreviewURL("");
      } catch (error) {
        console.log(error);
        alert("Erro ao enviar foto");
      }
    } else {
      alert("Senha incorreta");
    }
  };

  const handleSubmitVideo = async () => {
    console.log(md5(password));
    if (md5(password) === "5c0528724ec1226519bd4a98b5c0e4b1") {
      if (!fileVideo) {
        alert("Nenhum arquivo de vídeo selecionado");
        return;
      }

      const formData = new FormData();
      formData.append("file", fileVideo);

      try {
        await VideoService.Upload(eventName, formData);
        alert("Vídeo salvo com sucesso");
        // Limpar estado após envio bem-sucedido
        setFileVideo(null);
      } catch (error) {
        console.log(error);
        alert("Erro ao enviar vídeo");
      }
    } else {
      alert("Senha incorreta");
    }
  };

  const deleteAll = async () => {
    try {
      if (md5(password) === "26442effb42e24d42f179f343c89e419") {
        await PhotoService.DeleteAll(eventName);
        alert("Imagens deletadas com sucesso");
        await VideoService.DeleteAll(eventName);
        alert("Vídeos deletados com sucesso");
        setPassword("");
      } else {
        alert("Senha incorreta");
      }
    } catch (error) {
      console.log(error);
      alert("Erro ao deletar arquivos");
    }
  };

  return (
    <div className="containerPrincipal">
      <div>
        <div className="containerPreview">
          {previewURL && <img src={previewURL} alt="Preview" />}
        </div>

        <h2>Upload de Foto</h2>
        <div>
          <input
            className="form-control senha"
            placeholder="Digite aqui a senha para enviar"
            style={{ width: "100%" }}
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            autoFocus
          />
        </div>
        <input
          className="btn btn-primary"
          type="file"
          onChange={handleFileChange}
          style={{ width: "100%" }}
        />

        <div>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <button
            className="btn btn-success"
            onClick={handleSubmit}
            disabled={!file}
          >
            Enviar Foto
          </button>
        </div>
      </div>

      <div>
        <h2>Upload de Vídeo</h2>
        <input
          className="btn btn-primary"
          type="file"
          onChange={handleFileChangeVideo}
          style={{ width: "100%" }}
        />

        <div>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <button
            className="btn btn-success"
            onClick={handleSubmitVideo}
            disabled={!fileVideo}
          >
            Enviar Vídeo
          </button>
        </div>
      </div>

      <div>
        <br />
        <button className="btn btn-danger" onClick={() => setModalShow(true)}>
          Apagar todos arquivos
        </button>
      </div>

      <div className={`modal ${modalShow ? "show" : ""}`}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirmar exclusão?</h5>
            </div>

            <div className="modal-body">
              <p>
                Você irá dropar a tabela no banco e apagar todas as imagens e
                vídeos salvos no servidor. Tem certeza?
              </p>
              <input
                className="form-control"
                placeholder="Digite a senha para deletar"
                style={{ width: "100%" }}
                value={password}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setModalShow(false)}
              >
                Cancelar
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setModalShow(false);
                  deleteAll();
                }}
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadMedia;
