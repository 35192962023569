import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import appConfig from "../appConfig.json";
const seedrandom = require("seedrandom");

function VideoGlobal(props) {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [video, setVideo] = useState(""); // Estado para armazenar a URL do vídeo atual
  const playerRef = useRef(null);
  const [links, setLinks] = useState([]);
  const [url, setUrl] = useState(null);
  
  const handleVideoEnd = async () => {
    if (currentVideoIndex + 1 >= props.urls.length) {
      props.onVideosEnd(); // Chama a função para voltar às fotos após o último vídeo
    } else {
      setCurrentVideoIndex(currentVideoIndex + 1);
    } 
  };

  useEffect(() => {
    function shuffleArrayWithSeed(array, seed) {
      const rng = seedrandom(seed);
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(rng() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    }

    setLinks(shuffleArrayWithSeed(props.urls, Math.random()?.toString()));
  }, [props.urls]);

  useEffect(() => {
    setUrl(
      process.env.NODE_ENV !== "development"
        ? appConfig.API.PROD_ENDPOINT_VIDEO
        : appConfig.API.DEV_ENDPOINT_VIDEO
    );

    if (links.length > 0) {
      setVideo( links[currentVideoIndex]);
    }
  }, [currentVideoIndex, links, props.eventName]);

  let style = {};
  if (props?.style) {
    style = {
      position: "absolute",
      top: 0,
      left: 0,
      overflow: "hidden",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundSize: "cover",
    };
  } else {
    style = {
      position: "absolute",
      top: 0,
      left: 0,
      overflow: "hidden",
      backgroundColor: "#000",
    };
  }

  return (
    <ReactPlayer
      ref={playerRef}
      url={`${url}/${props.eventName}/${video}`} 
      playing
      width="100%"
      height="100%"
      style={style}
      onEnded={handleVideoEnd}
      muted={true}
      playbackRate={0.75}
    />
  );
}

export default VideoGlobal;
