import axios from 'axios';

const VideoService = {
	Upload(eventName,formData) {
		return axios
			.post(`/Video/Upload/${eventName}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((error) => {
				return Promise.reject(error);
			});
	},
	GetVideos(eventName,tag) {
		return axios
			.post(`/Video/GetVideos/${eventName}`)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((error) => {
				return Promise.reject(error);
			});
	},
	DeleteAll(eventName) {
		return axios
			.post(`/Video/DeleteAll/${eventName}`)
			.then((response) => {
				return Promise.resolve(response.data);
			})
			.catch((error) => {
				return Promise.reject(error);
			});
	},
};

export default VideoService;