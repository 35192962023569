import ReactDOM from "react-dom/client";
import "./style.css";
import "./bootstrap.min.css";
import Home from "./pages/home";
import Home2 from "./pages/home2";
import Home3 from "./pages/home3";
import UploadMedia from "./pages/upload";
import Monitor1 from "./pages/monitor1/monitor1";
import Monitor2 from "./pages/monitor1/monitor1";
import Homenagem from "./pages/homenagem/Homenagem";
import axios from "axios";
import * as serviceWorker from "./serviceWorker";
import NoEvent from "./pages/noEvent";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import appConfig from "./appConfig.json"

if (process.env.NODE_ENV !== "development") {
  axios.defaults.baseURL = appConfig.API.PROD;
} else {
  axios.defaults.baseURL = appConfig.API.DEV;
}

axios.defaults.headers.common["hash"] = "2bb77fd6284a6818d38b690b8de25c22";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/home2" exact>
          <Route index element={<Home2 />} />
        </Route>
        <Route path="/home3" exact>
          <Route index element={<Home3 />} />
        </Route>
        <Route path="/:eventName/upload">
          <Route index element={<UploadMedia />} />
        </Route>
        <Route path="/monitor1" exact>
          <Route index element={<Monitor1 />} />
        </Route>
        <Route path="/monitor2" exact>
          <Route index element={<Monitor2 />} />
        </Route>
        <Route path="/homenagem" exact>
          <Route index element={<Homenagem />} />
        </Route>
        <Route path="/:eventName">
          <Route index element={<Home />} />
        </Route>
        <Route path="*" element={<NoEvent />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// Registra o service worker
serviceWorker.register();
